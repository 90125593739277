@import "../../assets/scss/theme.scss";

form {
  .container {
    display: flex;
    color: white;
    background-color: black;
  }

  .dialog-create-item {
    display: inline-block;
    padding-right: 5%;
  }
  .dialog-create-item_right {
    align-content: flex-end;
    float: right;
  }

  .createItemCategoryTextfield {
    padding-right: 1rem;
    width: 65%;
  }

  .createItemPriorityTextfield {
    float: right;
    width: 25%;
  }
}
