@import "../../assets/scss/theme.scss";

.home {
  padding-top: 6rem;

  hr {
    margin: 0 5rem 0 5rem;
    border: 1px solid grey;
  }

  .intro {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;

    .left {
      z-index: 1;
      display: flex;
      flex-direction: column;
      .slogan {
        font-size: 72px;
      }
      .undertext {
        padding-left: 5rem;
        font-size: 24px;

        .welcomeBack {
          font-size: 40px;
          font-weight: 600;
        }
      }
      .details {
        padding: 0 0 5rem 5rem;
        margin-top: auto;
        font-size: 24px;
      }
    }

    .right {
      padding: 0 5rem 0 0;
      position: sticky;
      right: -15rem;
      img {
        width: 650px;
      }
    }
  }
}

@include max-phone {
  .home {
    padding-top: 1rem;
    .intro {
      .left {
        min-width: 100%;
        .slogan {
          font-size: 44px;
        }
        .undertext {
          padding-left: 1rem;
        }
        .details {
          padding: 0 0 2rem 1.5rem;
        }
      }
      .right {
        padding: 0;
        right: -116px;
        img {
          width: 400px;
          padding: 7rem 0 0 8rem;
        }
      }
    }
  }
}
