@import "../../assets/scss/theme.scss";

body {
  max-width: 100vw;
  margin: 0;
  height: 100%;
  padding: 0px;
}

.innerApp {
  height: 100vh;
  padding: 6rem 15% 0;
}

@include max-laptop {
  .innerApp {
    height: 100vh;
    padding: 6rem 5% 0;
  }
}

@include max-phone {
  .innerApp {
    height: 100vh;
    padding: 0 2.5%;
  }
  .innerApp:has(.home) {
    padding: 2rem 0 0 5% !important;
  }
  h1 {
    margin-top: 0;
    padding-top: 1rem;
  }
}

.App-link {
  color: #61dafb;
}
