@import "../../assets/scss/theme";

.login_container {
  margin: 0 auto;
  width: 50%;
  height: fit-content;
  background-color: #222222;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  &--content {
    padding: 20px 10%;

    .footer_items {
      align-items: center;
      display: flex;
      flex-direction: column;

      Button {
        margin: 1rem 0;
      }

      .footer_item_one {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        .MuiButtonBase-root {
          width: 100%;
        }
      }
      hr {
        width: 100%;
        margin-bottom: 1rem;
      }
      .footer_item_two {
        align-items: center;

        Button {
          background-color: $dark-accent;
        }
      }
    }
  }
}

.light .login_container {
  background-color: $light-primary;
}

@include max-phone {
  .login {
    .login_container {
      width: 95%;
    }
  }
}
