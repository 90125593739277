.MuiCardContent-root {
  padding: 8px 16px !important;
}

//from planner.scss
.MuiButtonBase-root {
  min-height: 56px !important;
  min-width: 56px !important;
}

.MuiIconButton-root {
  background: none !important;
  width: fit-content !important;
}

@include max-phone {
  .MuiDialogContent-root {
    padding: 16px 12px !important;
  }
}
