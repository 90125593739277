@import "../../assets/scss/theme";

.profile {
  word-wrap: break-word;

  .container {
    display: flex;
  }

  .left {
    flex: 1;
  }

  .right {
    flex: 1;
    padding: 1rem 1rem 0 0;
  }

  .profileContainer {
    float: left;
    width: 50%;

    .profile--content {
      margin: -3rem -0.5rem;
      padding: 4rem 2rem 2rem;
      width: calc(90%);
      border-radius: 8px;
      background-color: #252627;
    }
  }

  .profilePictureContainer {
    padding-left: 1.5rem;
    position: relative;
    max-width: 12.5rem;
  }

  .profilePictureContainer:hover .overlay {
    opacity: 1;
  }

  .profilePicture {
    max-width: 12.5rem;
    min-width: 12.5rem;
    max-height: 12.5rem;
    min-height: 12.5rem;
    border-radius: 5px;
    object-fit: cover;
  }

  .overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 98.5%;
    width: 89.5%;
    border-radius: 5px;
    position: absolute;
    top: 0;
    opacity: 0;
    transition: 0.5s ease;
    background-color: rgba(0, 0, 0, 0.55);
  }

  .buttonWrapper {
    margin-top: auto;
    display: flex;
    gap: 1rem;
  }

  .deleteButton,
  .editButton {
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    text-decoration-line: none;
    cursor: pointer;
    width: 100%;
    color: white;
    border: none;
    border-radius: 5px;
    outline: none;
  }

  .deleteButton {
    background: #e74c3c;
    &:hover {
      background: #ee3622;
    }
  }
  .editButton {
    background: #3498db;
    &:hover {
      background: #2099e9;
    }
  }

  hr {
    padding: 0;
    margin: 0.25rem 0;
  }

  .ModeEditOutlinedIcon {
    padding-top: 0.5rem;
    float: right;
    display: none;
  }

  .username,
  .email {
    border-radius: 5px;
    &:hover {
      background-color: $dark-secondary;
      margin: 0.25rem 0;
      padding: 0rem 0.5rem;
    }
    &:hover .ModeEditOutlinedIcon {
      display: unset;
    }
  }
  .usernameEdit {
    padding: 1rem 0;
  }

  .profileBrowser {
    float: left;
  }

  /*
THEME TOGGLE SWITCH
F5EB42 - sun inner
E4C74D - sun outer
FFFFFF - cloud inner
D4D4D2 - cloud outer
81C0D5 - parent outer
C0E6F6 - parent inner
FFFDF2 - moon inner
DEE1C5 - moon outer
FCFCFC - stars
*/

  body {
    background-color: #f3f3f3;
  }

  .dark-light-wrapper {
    margin: 15px 0;
  }

  /////////////////////////////////////////////////
  // Mixins
  /////////////////////////////////////////////////
  @mixin crater($top, $left, $size) {
    content: "";
    position: absolute;
    top: $top;
    left: $left;
    width: $size;
    height: $size;
    background-color: #efeeda;
    border-radius: 100%;
    border: 4px solid #dee1c5;
  }

  @mixin cloudBubble($top, $right, $width, $height, $deg) {
    content: "";
    display: block;
    position: relative;
    top: $top;
    right: $right;
    width: $width;
    height: $height;
    border: 8px solid #d4d4d2;
    border-radius: 100%;
    border-right-color: transparent;
    border-bottom-color: transparent;
    transform: rotateZ($deg);
    background-color: #fff;
  }

  /////////////////////////////////////////////////
  // Day State
  /////////////////////////////////////////////////
  .toggle {
    position: relative;
    display: inline-block;
    width: 100px;
    padding: 4px;
    border-radius: 40px;
  }

  .toggle:before,
  .toggle:after {
    content: "";
    display: table;
  }

  .toggle:after {
    clear: both;
  }

  .toggle-bg {
    position: absolute;
    top: -4px;
    left: -4px;
    width: 100%;
    height: 100%;
    background-color: #c0e6f6;
    border-radius: 40px;
    border: 4px solid #81c0d5;
    transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .toggle-input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 1px solid red;
    border-radius: 40px;
    z-index: 2;
    opacity: 0;
  }

  .toggle-switch {
    position: relative;
    width: 40px;
    height: 40px;
    margin-left: 50px;
    background-color: #f5eb42;
    border: 4px solid #e4c74d;
    border-radius: 50%;
    transition: all 0.1s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  }

  .toggle-switch-figure {
    position: absolute;
    bottom: -14px;
    left: -50px;
    display: block;
    width: 80px;
    height: 30px;
    border: 8px solid #d4d4d2;
    border-radius: 20px;
    background-color: #fff;
    transform: scale(0.4);
    transition: all 0.12s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    &:after {
      @include cloudBubble(-65px, -42px, 15px, 15px, 70deg);
    }
    &:before {
      @include cloudBubble(-25px, -10px, 30px, 30px, 30deg);
    }
  }

  .toggle-switch-figureAlt {
    @include crater(5px, 2px, 2px);

    box-shadow: 42px -7px 0 -3px #fcfcfc, 75px -10px 0 -3px #fcfcfc,
      54px 4px 0 -4px #fcfcfc, 83px 7px 0 -2px #fcfcfc, 63px 18px 0 -4px #fcfcfc,
      44px 28px 0 -2px #fcfcfc, 78px 23px 0 -3px #fcfcfc;

    transition: all 0.12s cubic-bezier(0.25, 0.46, 0.45, 0.94);
    transform: scale(0);
  }

  .toggle-switch-figureAlt:before {
    @include crater(-6px, 18px, 7px);
  }

  .toggle-switch-figureAlt:after {
    @include crater(19px, 15px, 2px);
  }

  /////////////////////////////////////////////////
  // Night State
  /////////////////////////////////////////////////
  .toggle-input:checked ~ .toggle-switch {
    margin-left: 0;
    border-color: #dee1c5;
    background-color: #fffdf2;
  }

  .toggle-input:checked ~ .toggle-bg {
    background-color: #484848;
    border-color: #202020;
  }

  .toggle-input:checked ~ .toggle-switch .toggle-switch-figure {
    margin-left: 40px;
    opacity: 0;
    transform: scale(0.1);
  }

  .toggle-input:checked ~ .toggle-switch .toggle-switch-figureAlt {
    transform: scale(1);
  }
}

@include max-phone {
  .profile {
    .profileContainer {
      width: 100%;

      .profilePictureContainer {
        padding: 2rem 0 0 3rem;
        max-width: 10rem;
        .profilePicture {
          min-width: 10rem;
          max-width: 10rem;
          min-height: 10rem;
          max-height: 10rem;
        }
        .overlay {
          top: 2rem;
          height: 10rem;
          width: 10rem;
        }
      }
      .profile--content {
        margin: -3rem 0.5rem 2rem;
        padding: 4rem 2rem 2rem;
        width: calc(100% - 5rem);
      }
    }
    .profileBrowser {
      margin: 1rem;
      width: calc(100% - 2rem);
    }
  }
}

.light {
  .profile {
    .profile--content {
      background-color: $light-primary;
    }
  }
}

.profile--content {
  .MuiFormControl-root {
    margin-bottom: 1rem !important;
    margin-left: 0 !important;
    top: 0.5rem !important;
  }
}
