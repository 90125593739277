@import "./variables";
@import "./breakpoints.scss";
@import "./global.scss";
@import "./materialOverwrites.scss";

html,
body {
  overflow: hidden;
  width: 100%;
}

h1,
h2,
h3,
h5,
h5,
h6,
p,
a,
span {
  font-family: "Montserrat", sans-serif !important;
}

p {
  font-size: 15px;
}

a {
  text-decoration: none;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.pageTitle {
  display: none;
}

.innerApp {
  margin: 0 auto;
  height: calc(100vh - $header-height);
  //No overflow on .planner-page Since Custom scroll has been implemented to keep the filters in-screen on scroll.
  &:not(:has(.planner)) {
    overflow-y: auto;
  }

  @include min-phone {
    max-width: 540px;
  }

  @include min-tablet {
    max-width: 720px;
  }

  @include min-laptop {
    max-width: 960px;
  }

  @include min-desktop {
    max-width: 1140px;
  }

  @include min-widescreen {
    max-width: 1320px;
  }
}

.dark {
  height: 100%;
  min-height: 100vh;
  flex-grow: 1;
  background-color: $dark-background;
  color: $dark-text;

  .textCenter {
    text-shadow: 0px 0px 15px $light-text;
  }

  h1,
  h2,
  h3,
  h5,
  h5,
  h6,
  p,
  span {
    color: $dark-text;
  }

  .pageTitle {
    color: $dark-text;
  }

  .MuiPaper-root {
    background-color: #252627 !important;

    .MuiSvgIcon-root {
      color: $light-color !important;
    }
  }

  .MuiFormLabel-root,
  .MuiFormLabel-root.MuiSvgIcon-root,
  .MuiInputBase-input,
  .MuiSelect-select {
    color: $dark-text !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: $dark-text !important;
  }
  .MuiSvgIcon-root {
    color: $dark-text !important;
  }
}

.light {
  height: 100%;
  min-height: 100vh;
  background-color: $light-background;
  color: $light-text;

  h1,
  h2,
  h3,
  h5,
  h5,
  h6,
  p,
  span {
    color: $light-text;
  }

  .Image {
    box-shadow: 0 0 20px 6px rgba(0, 0, 0, 0.3);
  }
  .textCenter {
    text-shadow: 0px 0px 15px $dark-text;
  }

  .pageTitle {
    color: $light-text;
  }

  .MuiPaper-root {
    background-color: $light-primary !important;
    border-color: $light-primary !important;

    .MuiSvgIcon-root {
      color: #252627 !important;
    }
  }
  .MuiSvgIcon-root {
    color: $dark-color !important;
  }
  .MuiFormLabel-root,
  .MuiFormLabel-root.MuiSvgIcon-root,
  .MuiInputBase-input,
  .MuiSelect-select {
    color: $light-text !important;
  }

  .MuiOutlinedInput-notchedOutline {
    border-color: $light-text !important;
  }

  .MuiIconButton-root {
    width: fit-content;
  }
  .MuiCard-root {
    box-shadow: 1px 1px 2.5px $light-primary !important;
    border: #fbfbfb;
    border-style: double;
    border-width: 1px;
  }
  .MuiSnackbarContent-message {
    color: black;
  }
}

@include max-phone {
  p,
  span {
    font-size: 90%;
  }

  .pageTitle {
    text-align: center !important;
    display: unset;
    font-size: 10px;
    color: $light-accent;
  }
}
