@import "../../assets/scss/theme";

.register_container {
  margin: 0 auto;
  width: 50%;
  height: fit-content;
  background-color: #222222;
  border-radius: 10px;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

  &--content {
    padding: 20px 10%;

    .input_field_top_bar {
      display: flex;
    }

    .input_field_top_bar_username {
      width: 47.5%;
    }

    .input_field_top_bar_email {
      width: 47.5%;

      margin-top: auto;
      justify-content: flex-end;
      margin-left: auto;
    }

    .footer_items {
      align-items: center;
      display: flex;
      flex-direction: column;

      Button {
        margin: 1rem 0;
      }

      .footer_item_one {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
        text-decoration: none;
        .MuiButtonBase-root {
          width: 100%;
        }
      }
      hr {
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }
}

.light .register_container {
  background-color: $light-primary;
}

@include max-phone {
  .register_container {
    width: 95%;
  }
}
