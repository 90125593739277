@import "../../assets/scss/theme.scss";

header {
  z-index: 99;

  height: 3rem;
  width: 100vw;
  box-sizing: border-box;
  position: fixed;

  a {
    padding: 12px;
    font-size: 18px;
    color: white;
    text-decoration: none;
  }

  .header-wrapper-desktop {
    padding: 15px 5%;
    margin: -5px 0;
    display: flex;
    justify-content: space-around;
    align-items: center;
    background-color: $dark-background;

    border-bottom: 1px solid $dark-primary;
    .logo {
      max-height: 3rem;
    }
  }
  .header-wrapper-mobile {
    display: none;
  }
}

@include max-tablet {
  .header a {
    float: none;
    display: block;
    text-align: left;
  }
  .header-right {
    float: none;
  }
}

@include max-phone {
  header {
    height: $header-height;
    width: 100%;
    position: fixed;
    text-align: center;
    bottom: 0;
    .header-wrapper-mobile {
      padding: 0 10%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $dark-background;
      border-top: 1px solid $dark-primary;

      a {
        padding: 4px;
      }
    }
    .header-wrapper-desktop {
      display: none;
    }
  }
}

.darkMode-Hover:hover {
  cursor: pointer;
  transform: scale(1.15);
}

.light {
  header {
    .header-wrapper-mobile,
    .header-wrapper-desktop {
      background-color: $light-background;
      border-bottom: 1px solid $light-secondary;
      border-top: 1px solid $light-secondary;
      a {
        color: $light-text;
      }
    }
  }
}
