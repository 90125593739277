@import "../../assets/scss/theme.scss";

.profileBrowser {
  width: 45%;
  padding-left: 5%;

  ul {
    padding-left: 0;
    list-style-type: none;

    .MuiCard-root {
      padding-bottom: 1rem;
      margin-bottom: 8px !important;
    }
    .MuiCard-root:last-child {
      margin-bottom: 64px !important;
    }

    li {
      margin: 5px 10px;

      .container {
        display: flex;
        color: white;

        .div-left {
          display: inline-block;

          p {
            display: flex;
            align-items: center;
            svg {
              padding-right: 0.25rem;
            }
          }
        }
      }
    }
  }
}

@include max-phone {
  .profileBrowser {
    display: contents;
    margin-top: 0;

    .searchInput,
    .resultResultProfileCard {
      padding: 0 0.5rem;
      margin-top: 0;
    }
  }
}

.MuiTextField-root {
  margin-top: 0 !important;
}
