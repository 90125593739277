@import "../../assets/scss/theme.scss";

.planner {
  height: 100%;
  .top-bar-wrapper {
    display: flex;
  }

  .top-bar {
    vertical-align: top;
    display: inline-block;
    box-sizing: border-box;
  }

  .item-one {
    margin-top: auto;
    justify-content: flex-end;
    flex-basis: 35%;
  }

  .item-two {
    margin-top: auto;
    justify-content: flex-end;
    flex-basis: 35%;
    margin-left: auto;
  }

  .item-three {
    margin-top: auto;
    justify-content: flex-end;
    margin-left: auto;
  }

  ul {
    padding: 0 $spacing 0 0;
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - 5.5rem);
    width: 80%;
  }

  li {
    list-style-type: none;
    padding-bottom: 4px;
  }

  .loader {
    list-style-type: none;
    width: 80%;
  }

  .card-content-top {
    display: flex;
    width: 100%;
  }

  .card-content-top-title {
    flex-basis: auto;
    padding-right: 1px;
    margin-right: 1px;
    overflow: hidden;
    flex-grow: 6;
  }

  .card-content-top-category {
    justify-content: flex-start;
    display: flex;
    align-items: center;
    margin-top: 5px;
    margin-left: auto;
    padding: 0 1rem;
    overflow: hidden;
    flex-grow: 0;
  }

  .card-content-top-rating {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    flex-grow: 0;
    margin-left: 2.5%;
    justify-content: flex-end;
  }

  .card-content-top-menu {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 0;

    margin-left: 2.5%;

    &:hover {
      cursor: pointer;
    }
  }

  .dialog-create-container,
  .dialog-edit-container {
    display: flex;
  }
  .dialog-create-item,
  .dialog-edit-item {
    color: black;
    display: inline-block;
    width: 80%;
  }
  .dialog-create-item_right,
  .dialog-edit-item_right {
    padding: 1.5% 0 0 7%;
    float: right;
    align-content: flex-end;
  }

  @include max-tablet {
    .top-bar {
      display: block;
    }
  }

  @include max-laptop {
    .top-bar-wrapper {
      display: flex;
    }

    ul {
      width: 90%;
    }

    li {
      list-style-type: none;
    }

    .loader {
      list-style-type: none;
      width: 90%;
    }
  }

  @include max-phone {
    ul {
      height: calc(100% - 7.9rem);
      width: 100%;
    }

    li {
      list-style-type: none;
      padding-right: $spacing-2;
      &:last-child {
        padding-bottom: $header-height;
      }
    }

    .loader {
      list-style-type: none;
      width: 100%;
    }

    .item-one {
      flex-basis: 45%;
    }

    .item-two {
      flex-basis: 45%;
    }

    .item-three {
      display: none;
    }

    .create-button-mobile {
      position: fixed;
      bottom: 90px;
      right: 30px;
    }
  }
  @include min-phone {
    .create-button-mobile {
      display: none;
    }
  }
}
