.notesDialog {
  .createNoteForm {
    height: 100%;
    width: 100%;

    .rdw-editor-toolbar {
      [title="Monospace"],
      [title="Superscript"],
      [title="Subscript"] {
        display: none;
      }
      .rdw-dropdown-optionwrapper {
        overflow-y: auto;
        max-width: 100%;
      }
    }
    .rdw-editor-main {
      margin-top: 1rem;
      min-height: 10rem;
      max-height: 20rem;
      border-radius: 3px;
      background-color: rgb(227, 227, 227);
      border-color: rgb(132, 132, 132);
      border-style: solid;
      border-width: 1px;

      .DraftEditor-root {
        padding: 0 0.5rem;

        .public-DraftEditor-content {
          min-height: 10rem;
          max-height: 20rem;
        }
      }
    }
  }

  .MuiPaper-root {
    margin: 0 22px 0 16px !important;
  }
}
