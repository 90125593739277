@import "../../assets/scss/theme.scss";

.createNoteForm {
  height: 400px;
  width: 400px;
}

.notes {
  height: 100%;
  margin: 0 auto;
  .topBar {
    padding-bottom: 1rem;

    .createButton {
      display: block;
      margin-left: auto;
      margin-right: 0;
    }
  }

  .notesWrapper {
    overflow-y: auto;
    ul {
      padding: 0;
      margin: 0;
      align-items: center;
      padding-left: 0;
      -webkit-columns: 200px 4; /* Chrome, Safari, Opera */
      -moz-columns: 200px 4; /* Firefox */
      columns: 200px 4;
      .note {
        list-style: none;
        flex-basis: calc((100% - 24rem) / 4);
        margin: 0 0 1rem;
        padding: 0.5rem 1rem 0.75rem 1rem;
        word-wrap: break-word;
        -webkit-column-break-inside: avoid;
        page-break-inside: avoid;
        break-inside: avoid-column;
        background-color: rgba(82, 84, 98, 0.9);
        border-radius: 5px;
        span {
          ul {
            padding-left: 0.75rem;
          }
          p {
            margin: 5px;
          }
        }
        &:last-child {
          margin-bottom: $header-height;
        }
      }
    }
  }
}

.light {
  .notes {
    .notesWrapper {
      .note {
        background-color: $light-primary;
      }
    }
  }
}

@include max-laptop {
  .notes {
    .notesWrapper {
      .note {
        flex-basis: calc((100% - 18rem) / 3);
      }
    }
  }
}

@include max-tablet {
  .notes {
    .notesWrapper {
      .note {
        flex-basis: calc((100% - 12rem) / 2);
      }
    }
  }
}

@include max-phone {
  .notes {
    .notesWrapper {
      ul {
        padding-right: 0.5rem;
        .note {
          flex-basis: calc((100% - 6rem) / 1);
          margin-bottom: 0.5rem;
          padding: 0.5rem 1rem 0.75rem 1rem;
        }
      }
    }

    .topBar {
      display: none;
    }

    .create-button-mobile {
      position: fixed;
      bottom: 90px;
      right: 30px;
    }
  }
}
@include min-phone {
  .create-button-mobile {
    display: none;
  }
}
