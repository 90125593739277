.login a,
.register a,
.resetPassword a {
  padding: 12px;
  font-size: 18px;
  color: #1565c0;
}

.passwordRow {
  display: flex;
}