.about {
  h2,
  h3 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .socials {
    a p {
      align-items: center;
      display: flex;

      svg {
        padding-right: 0.2rem;
      }
    }
  }
  .container {
    display: flex;
  }

  .left-div {
    flex: 1;
  }

  .right-div {
    flex: 1;
  }
}
